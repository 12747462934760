<template>
  <el-dialog
    visible
    append-to-body
    :show-close="false"
    width="30%"
    custom-class="delivery-item-create-modal rounded"
    @close="$emit('close')"
  >
    <template #title>
      <div class="p-4 bg-white rounded-top border-bottom">
        <div class="d-flex justify-content-between align-items-center">
          <h3>{{ $t(`delivery.addDeliveryItemModal.titles.${type}`) }}</h3>
          <Button type="text" class="p-0 text-typography-primary action-btn" @click="$emit('close')">
            <CloseIcon />
          </Button>
        </div>
      </div>
    </template>
    <el-form ref="form" :model="productForm" class="p-4 mt-2" label-position="top" size="mini">
      <el-form-item :label="translation.product.label" prop="productId" :rules="rules.product">
        <el-select
          v-model="productForm.productId"
          :style="{ width: '100%' }"
          filterable
          autocomplete="on"
          :placeholder="translation.product.placeholder"
        >
          <el-option
            v-for="product in products"
            :key="product.id"
            :label="generateProductLabel(product)"
            :value="product.id"
          />
        </el-select>
      </el-form-item>

      <el-form-item :label="translation.deliveryStatus.label" prop="selectedType" class="w-50">
        <el-select v-model="productForm.selectedType" :style="{ width: '100%' }" filterable autocomplete="on">
          <el-option key="delivered" :label="translation.deliveryStatus.options.delivered" value="delivered" />
          <el-option key="returned" :label="translation.deliveryStatus.options.returned" value="returned" />
        </el-select>
      </el-form-item>

      <el-form-item :label="translation.quantity.label" prop="quantity" :rules="rules.quantity" class="w-50">
        <InputNumber v-model="productForm.quantity" :placeholder="translation.quantity.placeholder" />
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="pb-4 px-4">
        <Button type="secondary" @click="$emit('close')">{{ $t('commons.cancel') }}</Button>
        <Button type="primary" @click="handleConfirm"> {{ $t('commons.apply') }}</Button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { computed, ref, getCurrentInstance } from 'vue';
import { Button, InputNumber } from '@/modules/core';
import { CloseIcon } from '@/assets/icons';

export default {
  name: 'AddReceivedReturnedGoodsItemModal',
  components: { Button, InputNumber, CloseIcon },
  props: {
    products: { type: Array, default: () => [] },
    deliveredProducts: { type: Array, default: () => [] },
    returnedProducts: { type: Array, default: () => [] },
    type: { type: String, required: true },
  },
  setup(props) {
    const { $i18n } = getCurrentInstance().proxy;

    const productForm = ref({
      productId: null,
      quantity: null,
      selectedType: props.type,
    });

    const rules = computed(() => ({
      product: [
        {
          required: true,
          message: $i18n.t('delivery.addDeliveryItemModal.inputs.rules.product.empty'),
        },
        {
          validator: (_, value, callback) => {
            if (props[`${productForm.value.selectedType}Products`].find(({ id }) => id === value)) {
              return callback(
                new Error(
                  $i18n.t(
                    `tasks.addReceivedReturnedGoodsItemModal.inputs.product.${productForm.value.selectedType}Already`
                  )
                ),
                {}
              );
            }

            callback();
          },
        },
      ],
      quantity: [
        {
          required: true,
          type: 'number',
          message: $i18n.t('delivery.addDeliveryItemModal.inputs.rules.quantity.empty'),
        },
        {
          validator: (_, value, callback) => {
            if (value < 0)
              return callback(new Error($i18n.t('delivery.addDeliveryItemModal.inputs.rules.quantity.negative')), {});
            if (value === 0)
              return callback(new Error($i18n.t('delivery.addDeliveryItemModal.inputs.rules.quantity.zero')), {});
            callback();
          },
        },
      ],
    }));

    const translation = computed(() => ({
      product: {
        label: $i18n.t('tasks.addReceivedReturnedGoodsItemModal.inputs.product.label'),
        placeholder: $i18n.t('tasks.addReceivedReturnedGoodsItemModal.inputs.product.placeholder'),
      },
      deliveryStatus: {
        label: $i18n.t('tasks.addReceivedReturnedGoodsItemModal.inputs.deliveryStatus.label'),
        options: {
          delivered: $i18n.t('tasks.addReceivedReturnedGoodsItemModal.inputs.deliveryStatus.options.delivered'),
          returned: $i18n.t('tasks.addReceivedReturnedGoodsItemModal.inputs.deliveryStatus.options.returned'),
        },
      },
      quantity: {
        label: $i18n.t('tasks.addReceivedReturnedGoodsItemModal.inputs.quantity.label'),
        placeholder: $i18n.t('tasks.addReceivedReturnedGoodsItemModal.inputs.quantity.placeholder'),
      },
    }));

    const generateProductLabel = (product) => (product.sku ? `${product.sku} / ${product.name}` : product.name);

    return {
      rules,
      translation,
      productForm,
      generateProductLabel,
    };
  },
  watch: {
    'productForm.selectedType'() {
      if (this.productForm.productId) {
        this.$refs.form.validateField('productId');
      }
    },
  },

  methods: {
    handleConfirm() {
      this.$refs.form.validate(async (isValid) => {
        if (isValid) {
          const data = { ...this.productForm };
          this.$emit('item-added', data);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

:deep(.delivery-item-create-modal) {
  .el-dialog__header,
  .el-dialog__body,
  .el-dialog__footer {
    padding: 0;
    color: $typography-primary;
  }
  input {
    text-align: start;
  }
}
</style>
